import React, { useState, useEffect, useRef } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { login } from "../actions/auth";
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { faHome, faKey, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import * as data from "react-device-detect";
import { getFetch1 } from '../services/getFetch1.service'
var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

const Login = (props) => {
    const [dataDevice, setDataDevice] = useState({
        browserName: data.browserName,
        browserVersion: data.browserVersion,
        deviceType: data.deviceType,
        engineName: data.engineName,
        engineVersion: data.engineVersion,
        fullBrowserVersion: data.fullBrowserVersion,
        getUA: data.getUA,
        isAndroid: data.isAndroid,
        isBrowser: data.isBrowser,
        isChrome: data.isChrome,
        isChromium: data.isChromium,
        isConsole: data.isConsole,
        isDesktop: data.isDesktop,
        isEdge: data.isEdge,
        isEdgeChromium: data.isEdgeChromium,
        isElectron: data.isElectron,
        isFirefox: data.isFirefox,
        isIE: data.isIE,
        isIOS: data.isIOS,
        isIOS13: data.isIOS13,
        isIPad13: data.isIPad13,
        isIPhone13: data.isIPhone13,
        isIPod13: data.isIPod13,
        isLegacyEdge: data.isLegacyEdge,
        isMIUI: data.isMIUI,
        isMacOs: data.isMacOs,
        isMobile: data.isMobile,
        isMobileOnly: data.isMobileOnly,
        isMobileSafari: data.isMobileSafari,
        isOpera: data.isOpera,
        isSafari: data.isSafari,
        isSamsungBrowser: data.isSamsungBrowser,
        isSmartTV: data.isSmartTV,
        isTablet: data.isTablet,
        isWearable: data.isWearable,
        isWinPhone: data.isWinPhone,
        isWindows: data.isWindows,
        isYandex: data.isYandex,
        mobileModel: data.mobileModel,
        mobileVendor: data.mobileVendor,
        osName: data.osName,
        osVersion: data.osVersion
    });

    const { user } = props;
    const { register, handleSubmit, setError, watch, formState: { errors } } = useForm({
        defaultValues: {
            user_login: user.user_login,
            user_password: user.user_password,
            user_localizacion: user.user_localizacion,
            user_accuracy: user.user_accuracy,
            user_pais: user.user_pais,
            user_avatar: user.avatar
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [Latitud, cambiarLatitud] = useState({ campo: '', valido: 'false' });
    const [Longitud, cambiarLongitud] = useState({ campo: '', valido: 'false' });

    const dispatch = useDispatch();

    
    if (localStorage.getItem("userSGSMacroZona") != null) {
        var decrypted = CryptoJS.AES.decrypt(localStorage.getItem("userSGSMacroZona"), process.env.REACT_APP_KEY);
        var currentUser = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } 

    const onSubmit = (data) => {

        if (!dataDevice.isSmartTV) {
            if (Latitud.valido === 'false') {
                Swal.fire({
                    icon: 'error',
                    title: "¡Error de georreferenciación!",
                    showConfirmButton: false,
                    text: "Debe dar acceso a la ubicación GPS.",
                    timer: 10000
                });

                return <></>;
            }

            getFetch1(Latitud.campo, Longitud.campo)
                .then(data => {
                    cambiarUbicaData((prevState) => {
                        return { ...prevState, valido: 'true', campo: data }
                    });
                    cambiarUbicaDataPais((prevState) => {

                        return { ...prevState, valido: 'true', campo: data, Bandera: `https://flagpedia.net/data/flags/h80/cl.webp` }

                    });
                });


        } else {

        }


        setIsLoading(true);
        try {

            dispatch(login(data.user_login.toLowerCase(), data.user_password, dataDevice))
                .then((function (response) {

                    if (response.data.accessToken) {
                        try {
                            localStorage.clear();
                            setIsLoading(false);
                            props.history.push("/");
                            window.location.reload();
                            Swal.fire({
                                icon: 'success',
                                html: "<b>Se verifico (" + data.user_login + ") correctamente.</b>",
                                showConfirmButton: false,
                                imageUrl: process.env.REACT_APP_LOGOEMPRESA,
                                imageWidth: 220,
                                imageHeight: 45,
                                timer: 15000
                            })
                            if (!dataDevice.isSmartTV) {
                                return <Redirect from="/" to="/ingresodenuncia" />;
                            } else {
                                return <Redirect from="/" to="/mapacasosactivos" />;
                            }
                        } catch (error) {
                            setIsLoading(false);

                        }
                    }
                    //return response.data;
                }))
                .catch((error) => {
                    setIsLoading(false);
                })


        } catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: "Error, No se pudo verificar al usuario: (" + data.user_login + ")",
                showConfirmButton: false,
                text: "El login o el password son incorrectos, revice los datos ingresados.!",
                timer: 10000
            });
        }
    };


    const [ubicacion, cambiarUbica] = useState({ campo: '', valido: 'false' });

    const [ubicaciondatapais, cambiarUbicaDataPais] = useState({ campo: '', valido: 'false', Bandera: 'https://raw.githubusercontent.com/MeRahulAhire/intl-tel-code-select/master/phone_icon.png' });
    const [ubicaciondata, cambiarUbicaData] = useState({ campo: 'georeferenciando...', valido: 'false' });


    //---------------------------------------------------------------

    const [isMounted, setIsMounted] = useState(true);
    useEffect(() => {
        if (isMounted) {
            myFunction1();
            return () => {
                cambiarUbica({});
                cambiarLatitud({});
                cambiarLongitud({});
            };
        }
    }, []);
    useEffect(() => {
        return () => {
            setIsMounted(false);
        }
    }, []);

    const myFunction1 = () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                let positionLatLon = [position.coords.latitude + ', ' + position.coords.longitude];

                cambiarUbica((prevState) => {
                    return { ...prevState, valido: 'true', campo: positionLatLon }
                });
                cambiarLatitud((prevState) => {
                    return { ...prevState, valido: 'true', campo: position.coords.latitude }
                });
                cambiarLongitud((prevState) => {
                    return { ...prevState, valido: 'true', campo: position.coords.longitude }
                });
            })
    }
    //---------------------------------------------------------------

    function myFunction() {
        document.body.style.backgroundColor = "#ffff";
        document.body.style.backgroundImage = "url('./assets/icons/FondoBase05.png')";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundPosition = "center center";
    }

    myFunction();

    if (currentUser) {

                if (!dataDevice.isSmartTV) {
                    return <Redirect from="/" to="/mapascasosactivosmz" />;
                } else {
                    return <Redirect from="/" to="/mapascasosactivosmz" />;
                }

    }

    return (
        <Form className="content" onSubmit={handleSubmit(onSubmit)}>
            <motion.div
                initial={{ x: '-100vw' }}
                animate={{ x: 0 }}
                transition={{ stiffness: 150 }}
            >
                <Form.Group className="col-titulo-login" controlid="LoginPass">
                    <Container className="um_data-login-row container2">
                        <Row >
                            <div className="container2">
                                <img
                                    className="center logo-empresa1"
                                    src={process.env.REACT_APP_LOGOEMPRESA}
                                    loading="lazy" />
                                <br />
                            </div>
                        </Row>

                        <Row className="center-texto">
                            <Form.Label className="left-texto" column="lg" lg={4}>
                                Usuario
                            </Form.Label>
                            <Col xs="auto" md="auto">
                                <Form.Control
                                    type="text"
                                    name="user_login"
                                    placeholder="Ej: jperez"
                                    autoComplete="new-user"

                                    {...register("user_login", {
                                        required: {
                                            value: true,
                                            message: 'Usuario es requerido'
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: 'No más de 20 carácteres!'
                                        },
                                        minLength: {
                                            value: 4,
                                            message: 'Mínimo 4 carácteres'
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9_-]{4,16}$/,
                                            message: 'Usuario sólo puede tener letras, numeros, guion y guion_bajo.'
                                        }
                                    })}
                                    className={`mb-3 ${errors.user_login ? 'input-error' : ''}`}
                                />
                                {errors.user_login && (
                                    <p className="errorMsg">{errors.user_login.message}</p>
                                )}
                            </Col>
                        </Row>

                        <Row className="center-texto">
                            <Form.Label className="left-texto" column="lg" lg={4}>
                                Password
                            </Form.Label>
                            <Col xs="auto" md="auto">
                                <Form.Group controlid="user_password">
                                    <Form.Control
                                        type="password"
                                        name="user_password"
                                        placeholder="(4-12 digitos)"
                                        autoComplete="new-password"

                                        {...register("user_password", {
                                            required: {
                                                value: true,
                                                message: 'Password es requerido'
                                            },
                                            maxLength: {
                                                value: 12,
                                                message: 'No más de 12 digitos!'
                                            },
                                            minLength: {
                                                value: 4,
                                                message: 'Mínimo 4 digitos!'
                                            },
                                            pattern: {
                                                value: /^[0-9]{4,12}$/,
                                                message: 'Password sólo puede tener de 4 a 12 digitos.'
                                            }
                                        })}
                                        className={`mb-3  ${errors.user_password ? 'input-error' : ''}`}
                                    />
                                    {errors.user_password && (
                                        <p className="errorMsg">{errors.user_password.message}</p>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row className="btn d-grid gap-2">


                            <Button
                                variant={ubicacion.valido === 'false' ? "danger" : "primary"}
                                type="submit"
                                value="ingresar"
                                disabled={isLoading}
                                size="lg"
                            >
                                {ubicacion.valido === 'true' ?
                                    <span>
                                        {isLoading ? <span>Verificando... <Spinner animation="grow" variant="light" /> </span> : 'Ingresar'}
                                    </span> :
                                    <Spinner variant="light" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>}

                            </Button>
                        </Row>
                    </Container>

                    <Container className="um_data-login-row container2">
                        <p className="center-texto-SinTop texto-label-login-U">
                            © {new Date().getFullYear()}{" "} Bpsystem Chile - SGS (v.{process.env.REACT_APP_VER})
                        </p>
                    </Container>
                    <Container className="um_data-login-row container2">
                                    <a className="center-texto-SinTop" href="https://www.bpsystem.cl" target="_blank" >
                                        <FontAwesomeIcon className="icon-Bpsystem" icon={faHome} size="lg" />
                                    </a>
                    </Container>
                </Form.Group>
            </motion.div>
        </Form>

    );
};

export default Login;