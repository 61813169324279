import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//import { HashRouter, Route, Switch } from "react-router-dom";

import Login from "./components/Login";
import Error404 from "./components/error404";
import Welcome from "./components/Welcome";


import Swal from 'sweetalert2';

import "./scss/style.scss";

const App = () => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  const updateUser = (data) => {
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const resetUser = () => {
    setUser({});
  };

  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    if (isMounted) {
      history.listen((location) => {
        dispatch(clearMessage()); // clear message when changing location
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    }
  }, []);

  const logOut = () => {
    dispatch(logout());
  };

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )






  // Containers
  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

  return (
    <React.Suspense fallback={loading}>
      <Router>
        <Switch>
          <Route
            exact={true}
            path="/"
            render={(props) => <Login {...props} user={user} updateUser={updateUser} />} />

          <Route
            path="/dashboard"
            render={(props) => <DefaultLayout {...props} />} />

          <Route
            path="/dashboardtipo"
            render={(props) => <DefaultLayout {...props} />} />

          <Route
            path="/AnalisisTactico"
            render={(props) => <DefaultLayout {...props} />} />


          <Route
            path="/mapascasosactivosmz"
            render={(props) => <DefaultLayout {...props} />} />

          <Route
            path="/mapascasosactivosderivados"
            render={(props) => <DefaultLayout {...props} />} />

          <Route
            path="/busquedaimagenes"
            render={(props) => <DefaultLayout {...props} />} />

          <Route
            path="/mapaUserGeo"
            render={(props) => <DefaultLayout {...props} />} />

          <Route
            path="/busquedaall"
            render={(props) => <DefaultLayout {...props} />} />

          <Route component={Error404} />

        </Switch>
      </Router>
    </React.Suspense >
  )
};

export default App;