
import axios from "axios";
import Swal from 'sweetalert2';

var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");

const API_URL0 = process.env.REACT_APP_API_API_URL
const API_URL = process.env.REACT_APP_API_AUTH_URL
const API_URL1 = process.env.REACT_APP_API_EMAIL_URL
const API_URL2 = process.env.REACT_APP_API_EMAIL_URL_IMG


const login = (username, password, dataDevice) => {

  return axios.post(API_URL + "signin", {
    username: username,
    password: password,
    dataDevice: dataDevice
  })
    .then(function (response) {
      var key = process.env.REACT_APP_KEY
      if (response.data.accessToken) {
        try {

          //localStorage.setItem("userSGSMacroZona", JSON.stringify(response.data));
          var encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data), key);
          localStorage.setItem("userSGSMacroZona", encrypted);

        } catch (error) {
          console.error(error);
        }
      }
      return response.data;
    })
    .catch(function (error) {
      let coderror = error.response.status;
      console.log(error.response.data.message);
      console.log(coderror);
      Swal.fire({
        icon: (coderror == 403) ? 'warning' : 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        text: (coderror == 403) ? "Usuario: (" + username + "), No olvide revisar su bandeja de entrada, para verificar su email en el Sistema." : "No se pudo verificar al usuario: (" + username + ")",
        timer: 10000
      })

    });
}

const Url_caso = (numcaso, entidad, accessToken) => {
  return axios.post(API_URL2 + "image-search", {
    entidad: numcaso,
    numcaso: entidad
  }, {
    headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
  })
    .then(function (response) {
      if (response) {
        try {

          if (response.data.resources.length > 0) {
            return response.data.resources[0].url;
          } else {
            return process.env.REACT_APP_LOGOEMPRESA
          }
          //console.log(response.data)



        } catch (error) {
          console.error(error);
        }
      }
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const ingresoantecedenteCierre = (
  antecedente_antecedentes,
  antecedente_denuncia_id,
  antecedente_user_id,
  antecedente_username,
  antecedente_avatar,
  antecedente_foto,
  antecedente_pais,
  antecedente_accuracy,
  antecedente_currentLatitude,
  antecedente_currentLongitude,
  antecedente_medidas,
  antecedente_participantes,
  antecedente_detenidos,
  antecedente_cantidadDetenidos,
  denuncia_dias,
  accessToken
) => {
  return axios.post(API_URL + "ingresoantecedenteCierre", {
    antecedente_antecedentes: antecedente_antecedentes,
    antecedente_denuncia_id: antecedente_denuncia_id,
    antecedente_user_id: antecedente_user_id,
    antecedente_username: antecedente_username,
    antecedente_avatar: antecedente_avatar,
    antecedente_foto: antecedente_foto,
    antecedente_pais: antecedente_pais,
    antecedente_accuracy: antecedente_accuracy,
    antecedente_currentLatitude: antecedente_currentLatitude,
    antecedente_currentLongitude: antecedente_currentLongitude,
    antecedente_medidas: antecedente_medidas,
    antecedente_participantes: antecedente_participantes,
    antecedente_detenidos: antecedente_detenidos,
    antecedente_cantidadDetenidos: antecedente_cantidadDetenidos,
    denuncia_dias: denuncia_dias
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const ingresoantecedente = (
  antecedente_antecedentes,
  antecedente_denuncia_id,
  antecedente_user_id,
  antecedente_username,
  antecedente_avatar,
  antecedente_foto,
  antecedente_pais,
  antecedente_accuracy,
  antecedente_currentLatitude,
  antecedente_currentLongitude,
  denuncia_dias,
  accessToken
) => {


  return axios.post(API_URL + "ingresoantecedente", {
    antecedente_antecedentes: antecedente_antecedentes,
    antecedente_denuncia_id: antecedente_denuncia_id,
    antecedente_user_id: antecedente_user_id,
    antecedente_username: antecedente_username,
    antecedente_avatar: antecedente_avatar,
    antecedente_foto: antecedente_foto,
    antecedente_pais: antecedente_pais,
    antecedente_accuracy: antecedente_accuracy,
    antecedente_currentLatitude: antecedente_currentLatitude,
    antecedente_currentLongitude: antecedente_currentLongitude,
    denuncia_dias: denuncia_dias
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const logout = () => {
  localStorage.removeItem("userSGSMacroZona");
};

const verifyUser = (code) => {

  return axios.get(API_URL1 + "email/confirm/" + code).then((response) => {
    return response.data;
  });
};


const usertel = (email, username) => {
  return axios.post(API_URL1 + "api/auth/usertel/", {
    email: email,
    username: username
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {

      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const ingresoPlan = (
  tipo_planes,
  user_id,
  entidades,
  currentLatitude,
  currentLongitude,
  currentaccuracy,
  ubica,
  descripcion,
  accessToken
) => {
  return axios.post(API_URL1 + "api/auth/ingresoPlan/", {
    tipo_planes,
    user_id,
    entidades,
    currentLatitude,
    currentLongitude,
    currentaccuracy,
    ubica,
    descripcion,
    accessToken
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};


const cambioEstado = (
  user_id,
  user_usuario,
  user_email,
  user_nombre,
  user_estadoNew,
  accessToken
) => {
  return axios.post(API_URL1 + "api/auth/cambioEstado/", {
    user_id,
    user_usuario,
    user_email,
    user_nombre,
    user_estadoNew,
    accessToken
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(JSON.stringify(error.response.data.message));
      let coderror = error.response.status;
      console.log(JSON.stringify(coderror));
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }

    });
};





const insertafotoAntecedente = async (
  antecedente_id,
  denuncia_id,
  user_id,
  antecedente_foto,
  accessToken
) => {
  try {
    let urlAux = API_URL + "ingresodenuncia/ingfotoAntecedente";
    const response = await axios.post(urlAux, {
      antecedente_id,
      denuncia_id,
      user_id,
      antecedente_foto
    },
      {
        headers: { "x-access-token": accessToken }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDen = async (
  estado,
  entidad,
  varDay,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDen";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenAT = async (
  entidad,
  fecha_ini,
  fecha_fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenAT";
    const response = await axios.post(urlAux, {
      entidad,
      fecha_ini,
      fecha_fin
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

//----------------------------------------------------------------------------




const aggregate_FechaDiaS = async (
  estado,
  entidad,
  varDay,
  denuncia_tipo,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_FechaDiaS";
    const response = await axios.post(urlAux, {
      estado,
      varDay,
      utc,
      entidad,
      denuncia_tipo
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

//----------------------------------------------------------------------------

const aggregate_hora = async (
  estado,
  entidad,
  varDay,
  denuncia_tipo,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_hora";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay,
      denuncia_tipo,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_diaS = async (
  estado,
  entidad,
  varDay,
  denuncia_tipo,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_diaS";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay,
      utc,
      denuncia_tipo
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_FechaHora = async (
  estado,
  entidad,
  varDay,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_FechaHora";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      varDay,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log('error: ', error)
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};
//----------------------------------------------------------------------------

const aggregate_FechaHora_AT = async (
  varEntidad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_FechaHora_AT";
    const response = await axios.post(urlAux, {
      varEntidad,
      fecha_ini,
      fecha_fin,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log('error: ', error)
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_diaS_AT = async (
  entidad,
  fecha_ini,
  fecha_fin,
  denuncia_tipo,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_diaS_AT";
    const response = await axios.post(urlAux, {
      entidad,
      fecha_ini,
      fecha_fin,
      denuncia_tipo,
      utc,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

//----------------------------------------------------------------------------


const aggregate_horaTipo = async (
  varEntidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_horatipo";
    const response = await axios.post(urlAux, {
      varEntidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_horaTipo1 = async (
  varEntidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_horatipo1";
    const response = await axios.post(urlAux, {
      varEntidad,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      utc
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const aggregate_FechaHora1 = async (
  estado,
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  utc,
  accessToken
) => {
  try {
    let urlAux = API_URL + "aggregate_FechaHora1";
    const response = await axios.post(urlAux, {
      estado,
      utc,
      id_prioridad,
      fecha_ini,
      fecha_fin,
      entidad
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listAntecedentes = async (
  denuncia_id,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listAntecedentes/";
    const response = await axios.post(urlAux, {
      denuncia_id
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const listUsers = async (
  entidad,
  rol,
  accessToken
) => {
  try {
    let urlAux = API_URL + "listUsers/";
    const response = await axios.post(urlAux, {
      entidad,
      rol
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const lisDenCerrada = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerrada";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaImagenFechas = async (
  estado,
  entidad,
  Fecha_Ini,
  Fecha_Fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaImagenFechas";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      Fecha_Ini,
      Fecha_Fin,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};

const lisDenCerradaImagenNumCaso = async (
  estado,
  entidad,
  NunCaso,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenCerradaImagenNumCaso";
    const response = await axios.post(urlAux, {
      estado,
      entidad,
      NunCaso,
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const findUserGeoUltima = (
  userid,
  username,
  accessToken
) => {
  return axios.post(API_URL0 + "turnos/findusergeoultima", {
    userid: userid,
    username: username
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}


const insertaInicioProc = (
  denuncia_id,
  denunciaid,
  user_id,
  estado_asignacion,
  Codigo_Seguridad,
  Username_Seguridad,
  Seguridad_Avatar,
  Fechahora_Ult_Pos_Inicio,
  Location_Inicio,
  accuracy_Inicio,
  denuncia_dias,
  accessToken
) => {
  return axios.post(API_URL0 + "despacho/insertainicioproc", {
    denuncia_id: denuncia_id,
    denunciaid: denunciaid,
    user_id: user_id,
    estado_asignacion: estado_asignacion,
    Codigo_Seguridad: Codigo_Seguridad,
    Username_Seguridad: Username_Seguridad,
    Seguridad_Avatar: Seguridad_Avatar,
    Fechahora_Ult_Pos_Inicio: Fechahora_Ult_Pos_Inicio,
    Location_Inicio: Location_Inicio,
    accuracy_Inicio: accuracy_Inicio,
    denuncia_dias: denuncia_dias,
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}

const insertaTerminoProc = (
  denuncia_id,
  denunciaid,
  user_id,
  Fechahora_Ult_Pos_Termino,
  Location_Termino,
  accuracy_Termino,
  Codigo_Seguridad,
  accessToken
) => {
  return axios.post(API_URL0 + "despacho/insertaterminoproc", {
    denuncia_id: denuncia_id,
    denunciaid: denunciaid,
    user_id: user_id,
    Fechahora_Ult_Pos_Termino: Fechahora_Ult_Pos_Termino,
    Location_Termino: Location_Termino,
    accuracy_Termino: accuracy_Termino,
    Codigo_Seguridad: Codigo_Seguridad,
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (responseT) {
      return responseT.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}


const lisDenTipo = async (
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  accessToken
) => {
  try {
    let urlAux = API_URL + "lisDenTipo";
    const response = await axios.post(urlAux, {
      entidad,
      id_prioridad,
      fecha_ini,
      fecha_fin
    },
      {
        headers: { "x-access-token": accessToken, "Content-Type": "application/json" }
      });
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error.response.data.message));
    let coderror = error.response.status;
    console.log(JSON.stringify(coderror));
    if (coderror === 401) {
      Swal.fire({
        icon: 'info',
        title: "Por razones de seguridad su sesión ha finalizado.",
        showConfirmButton: false,
        text: "por favor ingrese sus datos nuevamente.",
        timer: 10000
      });
      logout();
      window.location.href = "/";
    }

  }
};


const ingresoantecedenteCierreDeriva = (
  antecedente_antecedentes,
  antecedente_denuncia_id,
  antecedente_user_id,
  antecedente_username,
  antecedente_avatar,
  antecedente_foto,
  antecedente_pais,
  antecedente_accuracy,
  antecedente_currentLatitude,
  antecedente_currentLongitude,
  antecedente_medidas,
  antecedente_participantes,
  antecedente_detenidos,
  antecedente_cantidadDetenidos,
  denuncia_dias,
  accessToken
) => {
  return axios.post(API_URL + "ingresoantecedenteCierreDeriva", {
    antecedente_antecedentes: antecedente_antecedentes,
    antecedente_denuncia_id: antecedente_denuncia_id,
    antecedente_user_id: antecedente_user_id,
    antecedente_username: antecedente_username,
    antecedente_avatar: antecedente_avatar,
    antecedente_foto: antecedente_foto,
    antecedente_pais: antecedente_pais,
    antecedente_accuracy: antecedente_accuracy,
    antecedente_currentLatitude: antecedente_currentLatitude,
    antecedente_currentLongitude: antecedente_currentLongitude,
    antecedente_medidas: antecedente_medidas,
    antecedente_participantes: antecedente_participantes,
    antecedente_detenidos: antecedente_detenidos,
    antecedente_cantidadDetenidos: antecedente_cantidadDetenidos,
    denuncia_dias: denuncia_dias
  },
    {
      headers: { "x-access-token": accessToken }
    })
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      let coderror = error.response.status;
      if (coderror === 401) {
        Swal.fire({
          icon: 'info',
          title: "Por razones de seguridad su sesión ha finalizado.",
          showConfirmButton: false,
          text: "por favor ingrese sus datos nuevamente.",
          timer: 10000
        });
        logout();
        window.location.href = "/";
      }
    });
}


export default {
  login,
  logout,
  verifyUser,
  usertel,
  lisDen,
  lisDenAT,
  aggregate_hora,
  aggregate_diaS,
  aggregate_FechaHora,
  aggregate_FechaHora_AT,
  aggregate_diaS_AT,
  aggregate_FechaDiaS,
  listUsers,
  ingresoantecedente,
  insertafotoAntecedente,
  listAntecedentes,
  ingresoantecedenteCierre,
  ingresoantecedenteCierreDeriva,
  Url_caso,
  lisDenCerrada,
  lisDenCerradaImagenFechas,
  lisDenCerradaImagenNumCaso,
  cambioEstado,
  ingresoPlan,
  findUserGeoUltima,
  insertaInicioProc,
  insertaTerminoProc,
  lisDenTipo,
  aggregate_horaTipo,
  aggregate_horaTipo1,
  aggregate_FechaHora1
};
