import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  TURNO_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './types'

import AuthService from '../services/auth.service'


export const login = (username, password, dataDevice) => (dispatch) => {
  return AuthService.login(username, password, dataDevice).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      })

      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: LOGIN_FAIL,
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const Url_caso = (numcaso, entidad, accessToken) => (dispatch) => {
  return AuthService.Url_caso(numcaso, entidad, accessToken).then(
    (data) => {
      return Promise.resolve(data)
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: LOGIN_FAIL,
      })

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      })

      return Promise.reject()
    },
  )
}

export const ingresoantecedente =
  (
    antecedente_antecedentes,
    antecedente_denuncia_id,
    antecedente_user_id,
    antecedente_username,
    antecedente_avatar,
    antecedente_foto,
    antecedente_pais,
    antecedente_accuracy,
    antecedente_currentLatitude,
    antecedente_currentLongitude,
    denuncia_dias,
    accessToken,
  ) =>
  (dispatch) => {
    return AuthService.ingresoantecedente(
      antecedente_antecedentes,
      antecedente_denuncia_id,
      antecedente_user_id,
      antecedente_username,
      antecedente_avatar,
      antecedente_foto,
      antecedente_pais,
      antecedente_accuracy,
      antecedente_currentLatitude,
      antecedente_currentLongitude,
      denuncia_dias,
      accessToken,
    ).then(
      (data) => {
        return Promise.resolve(data)
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()

        dispatch({
          type: LOGIN_FAIL,
        })

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        })

        return Promise.reject()
      },
    )
  }

export const ingresoantecedenteCierre =
  (
    antecedente_antecedentes,
    antecedente_denuncia_id,
    antecedente_user_id,
    antecedente_username,
    antecedente_avatar,
    antecedente_foto,
    antecedente_pais,
    antecedente_accuracy,
    antecedente_currentLatitude,
    antecedente_currentLongitude,
    antecedente_medidas,
    antecedente_participantes,
    antecedente_detenidos,
    antecedente_cantidadDetenidos,
    denuncia_dias,
    accessToken,
  ) =>
  (dispatch) => {
    return AuthService.ingresoantecedenteCierre(
      antecedente_antecedentes,
      antecedente_denuncia_id,
      antecedente_user_id,
      antecedente_username,
      antecedente_avatar,
      antecedente_foto,
      antecedente_pais,
      antecedente_accuracy,
      antecedente_currentLatitude,
      antecedente_currentLongitude,
      antecedente_medidas,
      antecedente_participantes,
      antecedente_detenidos,
      antecedente_cantidadDetenidos,
      denuncia_dias,
      accessToken).then(
        (data) => {

          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: LOGIN_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };

export const listAntecedentes = (
  denuncia_id,
  accessToken) => (dispatch) => {
    return AuthService.listAntecedentes(
      denuncia_id,
      accessToken).then(
        (data) => {

          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: LOGIN_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };


export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const usertel = (email, username) => (dispatch) => {
  return AuthService.usertel(email, username).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const cambioEstado = (
  user_id,
  user_usuario,
  user_email,
  user_nombre,
  user_estadoNew,
  accessToken
) => (dispatch) => {
  return AuthService.cambioEstado(
    user_id,
    user_usuario,
    user_email,
    user_nombre,
    user_estadoNew,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const insertafotoAntecedente = (
  antecedente_id,
  denuncia_id,
  user_id,
  antecedente_foto,
  accessToken
) => (dispatch) => {
  return AuthService.insertafotoAntecedente(
    antecedente_id,
    denuncia_id,
    user_id,
    antecedente_foto,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const lisDen = (
  estado,
  entidad,
  denuncia_tipo,
  varDay,
  accessToken
) => (dispatch) => {
  return AuthService.lisDen(
    estado,
    entidad,
    denuncia_tipo,
    varDay,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};




export const lisDenTipo = (
  entidad,
  id_prioridad,
  fecha_ini,
  fecha_fin,
  accessToken
) => (dispatch) => {
  return AuthService.lisDenTipo(
    entidad,
    id_prioridad,
    fecha_ini,
    fecha_fin,
    accessToken
  ).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};




  

  export const ingresoantecedenteCierreDeriva =
  (
    antecedente_antecedentes,
    antecedente_denuncia_id,
    antecedente_user_id,
    antecedente_username,
    antecedente_avatar,
    antecedente_foto,
    antecedente_pais,
    antecedente_accuracy,
    antecedente_currentLatitude,
    antecedente_currentLongitude,
    antecedente_medidas,
    antecedente_participantes,
    antecedente_detenidos,
    antecedente_cantidadDetenidos,
    denuncia_dias,
    accessToken,
  ) =>
  (dispatch) => {
    return AuthService.ingresoantecedenteCierreDeriva(
      antecedente_antecedentes,
      antecedente_denuncia_id,
      antecedente_user_id,
      antecedente_username,
      antecedente_avatar,
      antecedente_foto,
      antecedente_pais,
      antecedente_accuracy,
      antecedente_currentLatitude,
      antecedente_currentLongitude,
      antecedente_medidas,
      antecedente_participantes,
      antecedente_detenidos,
      antecedente_cantidadDetenidos,
      denuncia_dias,
      accessToken).then(
        (data) => {

          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch({
            type: LOGIN_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });

          return Promise.reject();
        }
      );
  };